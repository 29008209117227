//generates share link and if copy set to true, will add it to clipboard
export default (parameters = {}, copy) => {
	let queryString = () => {
		// example:
		// ?techCount=9&hoursSpent=8&dailyDistance=25&avgWage=13.95&avgDriveTime=4&avgFuelPrice=3.50
		let hashes = Object.keys(parameters).map((key) => {
			if (key === 'displayPrintView') {
				return `print=${parameters[key]}`;
			} else {
				return `${key}=${parameters[key]}`;
			}
		});
		return `?${hashes.join('&')}`;
	};
	const browser = typeof window !== 'undefined';

	if (browser) {
		let link = `${window.location.href}${queryString()}`;

		if (copy) {
			const textField = document.createElement('textarea');
			textField.innerText = link;
			document.body.appendChild(textField);
			textField.select();
			document.execCommand('copy');
			textField.remove();
		}
		return link;
	}
};
