// Please refer to the calc description in the read me


export const calcCostCurrent = ({techCount, dailyDistance, avgFuelPrice, avgWage, hoursSpent, timePeriod}) => (
  (((techCount * dailyDistance * (0.176 + 0.091 + 0.022 + 0.05 + 0.013 + (avgFuelPrice / 10)) + avgWage * hoursSpent) * 250)) / timePeriod
);
export const calcCostSavings = (costCurrent) => (costCurrent * 0.164);

export const calcTimeCurrent = (hoursSpent, timePeriod) => ((hoursSpent * 251) / timePeriod);
export const calcTimeSaved = (hoursSpent, timePeriod) => ((hoursSpent * 83) / timePeriod);

export const calcWorkCurrent = (avgDriveTime, timePeriod) => (avgDriveTime * 250) / timePeriod;
export const calcWorkSavings = (workCurrent) => (workCurrent * 0.254);

export const calcDifference = (a, b) => (a - b);