import React, {
	useState,
	useEffect,
	useReducer,
	useCallback,
	useMemo,
	createContext,
} from 'react';
import {
	calcCostCurrent,
	calcCostSavings,
	calcDifference,
	calcTimeCurrent,
	calcTimeSaved,
	calcWorkCurrent,
	calcWorkSavings,
} from '../components/Roi/utilities/savingsCalculator';
import useProductGetter from '../components/Roi/utilities/hooks/useProductGetter';
import emailButtonClickHandler from '../components/Roi/utilities/emailButtonClickHandler';
import {
	formValuesReducer,
	initialFormValues,
} from '../components/Roi/utilities/reducers/formValues';

const RoiContext = createContext();

export function RoiProvider({ children }) {
	const [formValues, updateFormValues] = useReducer(
		formValuesReducer,
		initialFormValues
	);

	// toggle which view to display (Print / Form & Graphs)
	const [displayPrintView, setPrintView] = useState(false);

	// toggle to let user know form was submitted
	const [formSubmitted, setFormSubmitted] = useState(false);

	// Checks for form parameters in URL query string
	// const [query] = useQueryParams({
	// 	techCount: NumberParam,
	// 	hoursSpent: NumberParam,
	// 	dailyDistance: NumberParam,
	// 	avgWage: NumberParam,
	// 	avgDriveTime: NumberParam,
	// 	avgFuelPrice: NumberParam,
	// 	print: BooleanParam,
	// 	name: StringParam,
	// 	email: StringParam,
	// });

	const setValues = useCallback((values) => {
		updateFormValues(values);
	}, []);

	// useEffect(() => {
	// 	const newValues = Object.keys(query).reduce((acc, key) => {
	// 		return query[key] !== undefined ? { ...acc, [key]: query[key] } : acc;
	// 	}, {});
	// 	setValues(newValues);
	// }, [setValues, query]);

	// Checks to see what product is within the URL query string
	const product = useProductGetter();

	// Marketo form Ids
	const formIds = {
		workwave: 'xxxx',
		pestpac: '2722',
		service: '3772',
		routeManager: '1816',
	};

	// FULL DEMO FORM FOR RM/SERVICE = 3398
	// FULL PP DEMO FORM = 2335/2338

	//clears search query so that if copied again, will not create a repetitive string
	// useEffect(() => {
	// 	const browser = typeof window !== 'undefined';

	// 	if (browser) {
	// 		const { location, history } = window;
	// 		return history.pushState(null, '', location.pathname);
	// 	}
	// }, []);

	const onPrintButtonClick = useCallback(() => {
		setPrintView(!displayPrintView);
	}, [setPrintView, displayPrintView]);

	const onEmailButtonClick = useCallback(() => {
		const browser = typeof window !== 'undefined';

		browser &&
			emailButtonClickHandler({
				formValues,
				form: window.MktoForms2.getForm(formIds[product]),
				onSuccess: setFormSubmitted(!formSubmitted),
			});
	}, [product, formValues, formSubmitted, formIds]);

	const {
		techCount,
		hoursSpent,
		dailyDistance,
		avgWage,
		avgDriveTime,
		avgFuelPrice,
		timePeriod,
	} = formValues;

	const costCurrent = useMemo(
		() =>
			calcCostCurrent({
				techCount,
				dailyDistance,
				avgFuelPrice,
				avgWage,
				hoursSpent,
				timePeriod,
			}),
		[techCount, dailyDistance, avgFuelPrice, avgWage, hoursSpent, timePeriod]
	);
	const costSavings = useMemo(
		() => calcCostSavings(costCurrent),
		[costCurrent]
	);
	const priceAfterSavings = useMemo(
		() => calcDifference(costCurrent, costSavings),
		[costCurrent, costSavings]
	);

	const timeCurrent = useMemo(
		() => calcTimeCurrent(hoursSpent, timePeriod),
		[hoursSpent, timePeriod]
	);
	const timeSaved = useMemo(
		() => calcTimeSaved(hoursSpent, timePeriod),
		[hoursSpent, timePeriod]
	);
	const timeAfterSavings = useMemo(
		() => Math.floor(calcDifference(timeCurrent, timeSaved)),
		[timeCurrent, timeSaved]
	);

	const workCurrent = useMemo(
		() => calcWorkCurrent(avgDriveTime, timePeriod),
		[avgDriveTime, timePeriod]
	);
	const workSavings = useMemo(
		() => calcWorkSavings(workCurrent),
		[workCurrent]
	);
	const workAfterSavings = useMemo(
		() => calcDifference(workCurrent, workSavings),
		[workCurrent, workSavings]
	);

	const calculatedValues = {
		costCurrent,
		costSavings,
		priceAfterSavings,
		timeCurrent,
		timeAfterSavings,
		timeSaved,
		workCurrent,
		workSavings,
		workAfterSavings,
		timePeriod,
	};

	return (
		<RoiContext.Provider
			value={{
				formValues,
				setValues,
				calculatedValues,
				product,
				displayPrintView,
				formSubmitted,
				onEmailButtonClick,
				onPrintButtonClick,
			}}>
			{children}
		</RoiContext.Provider>
	);
}

export default RoiContext;
