import {calcCostCurrent, calcCostSavings} from "./savingsCalculator";
import generateShareUrl from "./generateShareUrl";

export default ({formValues = {}, form = {} , onSuccess = () => {}}) => {
  // Submit marketo form here
  const marketoFormMap = {
    avgDriveTime: "calculatorAverageDailyWorkingHours",
    dailyDistance: "calculatorAverageDrivingDistance",
    avgFuelPrice: "calculatorAverageFuelPrice",
    avgWage: "calculatorAverageHourlyWage",
    hoursSpent: "calculatorHoursPlanningPerDay",
    timePeriod: "calculatorMonthlyorAnnual",
    techCount: "calculatorNumberofVehicles",
    priceAfterSavings: "calculatorSavings",
    email: "Email",
    name: "FirstName"
  };

  if (form) {
    Object.keys(formValues).map((key) => {
      const formKey = marketoFormMap[key];
      const value = formValues[key];

      if (key === "timePeriod") {
        form.setValues({"calculatorMonthlyorAnnual": () => (formValues[key] === 1) ? "annual" : "monthly"})
      } else {
        form.setValues({[formKey]: value})
      }
      return false
    });

    const {techCount, hoursSpent, dailyDistance, avgWage, avgFuelPrice, timePeriod} = formValues;
    const costCurrent = calcCostCurrent({techCount, dailyDistance, avgFuelPrice, avgWage, hoursSpent, timePeriod});

    form.setValues({
      calculatorSavings: calcCostSavings(costCurrent).toFixed(2),
      Route_Manager_Calculator_URL__c: generateShareUrl(formValues)
    });

    form.submit().onSuccess(() => {
      onSuccess();
      return false
    })
  }
};