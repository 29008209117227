import { createTheme } from '@material-ui/core/styles';

//upgraded gatsby-plugin-material-ui to 4.1 from 3.0.1 and added gatsby-legacy-polyfills
//to use SSR in gatsby to better render the nav. Might cause problems. Downgrade and remove
//respectively if necessary

let theme = createTheme({
	palette: {
		primary: {
			main: '#002D5C',
		},
		secondary: {
			main: '#2a7abc',
		},
	},
	white: '#FFFFFF',
	black: '#000000',
	workwaveBlue: '#002D5C',
	mediumBlue: '#055291',
	lightBlue: '#2a7abc',
	blueGreen: '#0f95a4',
	pestpacBlue: '#1E488A',
	serviceBlue: '#008AD1',
	servmanBlue: '#008AD1',
	routeManagerGreen: '#8CC82D',
	gpsBlue: '#21C2E6',
	workwaveOrange: '#DE6426',
	ifsPurple: '#873E8D',
	darkGray: '#33383B',
	mediumDarkGray: '#4B5B69',
	mediumGray: '#5F6973',
	mediumLightGray: '#8d8d8d',
	lightGray: '#ECECEE',
	superlightGray: '#F6F9FC',
	borderLightGray: 'rgba(207, 215, 223, 0.25)',
	typography: {
		fontFamily: ['Poppins', 'sans-serif'].join(','),
	},
	props: {
		MuiContainer: {
			maxWidth: 'lg',
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				body: {
					backgroundColor: '#FFFFFF',
				},
			},
		},
		MuiTypography: {
			h1: {
				fontSize: '3.75rem',
				//60px
				fontWeight: 700,
				letterSpacing: '-1.5px',
			},
			h2: {
				fontSize: '2.25rem',
				//36px
				fontWeight: 600,
				letterSpacing: '-.5px',
				lineHeight: 1.2,
			},
			h3: {
				fontSize: '2rem',
				//32px
				fontWeight: 600,
			},
			h4: {
				fontSize: '1.75rem',
				//28px
				fontWeight: 600,
				letterSpacing: '.25px',
			},
			h5: {
				fontSize: '1.5rem',
				//24px
				fontWeight: 600,
			},
			h6: {
				fontSize: '1.25rem',
				//20px
				fontWeight: 600,
				letterSpacing: '.15px',
				lineHeight: 1.3,
			},
			subtitle1: {
				fontSize: '1rem',
				//16px
				lineHeight: 1,
				fontWeight: 400,
			},
			subtitle2: {
				fontSize: '.875rem',
				//14px
				lineHeight: 1,
				fontWeight: 500,
			},
			body1: {
				fontSize: '1.125rem',
				//18px
				lineHeight: 1.4,
				// fontFamily: 'Roboto',
				fontWeight: 400,
				color: '#4B5B69',
			},
			body2: {
				fontSize: '1rem',
				//16px
				lineHeight: 1,
				// fontFamily: 'Roboto',
				fontWeight: 400,
				color: '#4B5B69',
			},
			caption: {
				fontSize: '.75rem',
				//12px
				lineHeight: 1,
				fontWeight: 400,
			},
			overline: {
				fontSize: '.75rem',
				//12px
				lineHeight: 1,
				fontWeight: 400,
				textTransform: 'uppercase',
			},
			// Available in v5
			// avatarLetter: {
			//   fontSize: '1.25rem',
			//   lineHeight: 1,
			//   fontWeight: 400,
			// },
			// inputLabel: {
			//   fontSize: '.75rem',
			//   lineHeight: 1,
			//   fontWeight: 400,
			// },
			// helperText: {
			//   fontSize: '.75rem',
			//   lineHeight: 1,
			//   fontWeight: 400,
			// },
			// inputText: {
			//   fontSize: '1rem',
			//   lineHeight: 1,
			//   fontWeight: 400,
			// },
		},

		MuiCard: {
			root: {
				background: '#fff',
				// boxShadow:
				// 	'0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
				padding: '15px',
				borderRadius: '4px',
			},
		},
		MuiInputBase: {
			root: {
				color: 'rgba(0, 0, 0, 0.87)',
				cursor: 'text',
				display: 'inline-flex',
				position: 'relative',
				fontSize: '1rem',
				boxSizing: 'border-box',
				alignItems: 'center',
				fontFamily: 'Poppins,sans-serif',
				fontWeight: '400',
				lineHeight: '1.1876em',
			},
		},
		MuiButton: {
			root: {
				borderRadius: '8px',
				boxShadow: '0 4px 6px rgba(50,50,93,0.11), 0 1px 3px rgba(0,0,0,0.08)',
				transition: 'all 0.15s ease',
				opacity: 1,
			},
			label: {
				fontSize: '.938rem',
				fontWeight: 600,
				whiteSpace: 'nowrap',
				textTransform: 'uppercase',
			},
			contained: {
				padding: '12px 32px',
				'& $label': {
					fontSize: '.938rem',
					fontWeight: 600,
				},
				'&:hover': {
					opacity: '0.85 !important',
				},
			},
			containedSizeSmall: {
				padding: '8px 24px',
				'& $label': {
					fontSize: '.938rem',
					fontWeight: 600,
				},
				'&:hover': {
					opacity: '0.85 !important',
				},
			},
			containedSizeLarge: {
				padding: '16px 32px',
				'& $label': {
					fontSize: '1.25rem',
					fontWeight: 600,
				},
				'&:hover': {
					opacity: '0.85 !important',
				},
			},
		},
		MuiTouchRipple: {
			child: {
				backgroundColor: '#77b0e0',
			},
		},
	},
});

theme.typography.h1 = {
	[theme.breakpoints.down(821)]: {
		fontSize: '2.75rem',
	},
};
// theme.typography = {
// 	[theme.breakpoints.down('xs')]: {
// 		// fontSize: '2.2rem',
// 		textAlign: 'left',
// 	},
// };
theme.typography.h1 = {
	[theme.breakpoints.down('xs')]: {
		fontSize: '2.2rem',
		textAlign: 'left',
	},
};
theme.typography.h2 = {
	[theme.breakpoints.down(821)]: {
		fontSize: '1.75rem',
	},
	[theme.breakpoints.down('xs')]: {
		textAlign: 'left',
	},
};
theme.typography.h3 = {
	[theme.breakpoints.down('xs')]: {
		textAlign: 'left',
	},
};
theme.typography.h4 = {
	[theme.breakpoints.down('xs')]: {
		textAlign: 'left',
	},
};
theme.typography.h5 = {
	[theme.breakpoints.down('xs')]: {
		textAlign: 'left',
	},
};
theme.typography.h6 = {
	[theme.breakpoints.down('xs')]: {
		textAlign: 'left',
	},
};
theme.typography.body1 = {
	[theme.breakpoints.down('xs')]: {
		textAlign: 'left',
	},
};
// theme.typography.body2 = {
// 	[theme.breakpoints.down('xs')]: {
// 		textAlign: 'left',
// 	},
// };
theme.overrides.MuiButton.label = {
	[theme.breakpoints.down(409)]: {
		fontSize: '.9rem',
	},
};
// console.log(theme);
//theme = responsiveFontSizes(theme);

export default theme;
