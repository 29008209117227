exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fintech-faq-js": () => import("./../../../src/pages/fintech/faq.js" /* webpackChunkName: "component---src-pages-fintech-faq-js" */),
  "component---src-pages-fintech-index-js": () => import("./../../../src/pages/fintech/index.js" /* webpackChunkName: "component---src-pages-fintech-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-why-servman-js": () => import("./../../../src/pages/why-servman.js" /* webpackChunkName: "component---src-pages-why-servman-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/Industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-product-feature-js": () => import("./../../../src/templates/ProductFeature.js" /* webpackChunkName: "component---src-templates-product-feature-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

